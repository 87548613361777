.report-header {
    justify-content: space-between;
    width: 100%;
}

.report-filter-item-container {
    margin-bottom: 0.1rem;

    .filter-icon-box {
        background-color: $primary;
        height: 38px;
        width: 38px;
        min-width: 38px;
        border-radius: 4px;
    }

    .filter-floor-box {
        margin-right: 20px;
    }

    .filter-date-box {
        display: flex;
        width: 250px;
        margin-left: 6px;
        margin-right: 6px;
        height: 38px;
        .form-control {
            padding: 0.35rem 0.9rem;
        }
    }
}
.report-table-container {
    padding: 0 16px;
    margin-bottom: 10px;
}

.report-table {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: calc(100vh - $header-height - $footer-height - 300px); /* Set the desired height for the table container */
    overflow-y: auto; /* Enable vertical scrolling */

    th {
        position: sticky;
        top: 0;
        text-align: center;
    }

    .collapse-icon {            
        width: 40px;
        text-align: center;
        margin-bottom: -5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
    }

    td {
        vertical-align: middle;
        text-align: center;
    }

    table > :not(caption) > * > * {
        padding: 0.55rem 0.6rem;
    }
}

.report-list-table {
    max-height: calc(100vh - $header-height - $footer-height - 240px);

    .sort-icon {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #999 transparent transparent transparent;
        margin-left: 5px;
        opacity: 0.3; /* Change the default opacity */
        transition: opacity 0.2s;
    }
    
    .sort-icon.asc {
        transform: rotate(180deg);
        opacity: 1;
    }
      
    .sort-icon.desc {
        opacity: 1;
    }

    th[data-sort="asc"] .sort-icon.asc {
        opacity: 1;
    }

    th[data-sort="asc"] .sort-icon.desc {
        opacity: 0.3;
    }
    
    th[data-sort="desc"] .sort-icon.asc {
        opacity: 0.3;
    }

    th[data-sort="desc"] .sort-icon.desc {
        opacity: 1;
    }

    th[data-sort="none"] .sort-icon.asc,
    th[data-sort="none"] .sort-icon.desc {
        opacity: 0.3;
    }

    .sort-icon-container {
        justify-content: space-around;
        align-content: center;
        height: 13px;
        margin-right: -10px;
        margin-top: -2px;
    }
}

.report-table::-webkit-scrollbar {
    width: 12px;
  }
  
.report-table::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.report-table::-webkit-scrollbar-thumb {
    background-color: $offText;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

.report-pagination-container {
    width: 100%;
    height: 50px;
    .page-link.active, .active > .page-link {
        color: black;
        background-color: #f1f1f1;
        border-color: #f1f1f1;
    }
}

.report-event-eventType {    
  padding: 2px 6px;
  color: white; 
  width: 100%;
  max-width: 150px;
  text-align: center;
  font-size: 0.825rem;
  border-radius: 4px;
}

.report-header-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.report-header-btn {
    width: 150px;    
    margin: 0 2px 2px;
    padding: 6.5px;
    height: 38px;
}

.report-save-modal-label {
    font-size: 0.85rem;
}

.report-save-modal-btn-container {
    margin-top: 20px;
}

.report-save-modal-btn {
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 10px;
}

.report-save-modal-error {
    color: $danger;
    font-weight: bold;
}

.report-save-table {
    flex: 1;

    .search-btn-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: -10px;
        margin-left: 10px;
        
        .search-btn-group {
            width: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .search-icon {                
                font-size: 18px;
                margin-top: 2px;
                z-index: 3;
            }

            .search-input {                
                margin-left: -28px !important;
                padding-left: 36px;
                z-index: 1;
            }
        }
    }
}

.report-save-table-btn {
    margin-left: 4px;
    margin-right: 4px;
}

.report-tab-container {
    background-color: white;
    width: 100%;
    .tab-pane  {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
    }

    .nav-link {        
        font-weight: 500;
    }
}

.report-header-report-name {
    margin-bottom: -14px;
    padding-right: 20px;
    margin-top: 4px;
    text-align: right;
    color: $primary;
}

.report-table-spin {
    height: calc(100vh - 340px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}