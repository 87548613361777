// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f3f6f9;
$gray-200: #eff2f7;
$gray-300: #e9ebec;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #878a99;
$gray-700: #495057;
$gray-800: #363d48;
$gray-900: #212529;
$black:    #000;

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:       #48b2d5;
$indigo:     #405189;
$purple:     #865ce2;
$pink:       #f672a7;
$red:        #ed5e5e;
$orange:     #f1963b;
$yellow:     #e8bc52;
$green:      #13c56b;
$teal:       #02a8b5;
$cyan:       #50c3e6;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $purple;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
$lightDark:     $gray-500;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
// scss-docs-end theme-colors-map

$boxBackground: #FCFBFB;
$off: #ECEDEE;
$text: #666666;
$offText: #AEB6BD;
$bed: #F9B233;
$toilet: $blue;
$toiletLight: #56BCDC4D;
$eventSystemError: #004B74;

:export {
  blue:       $blue;
  indigo:     $indigo;
  purple:     $purple;
  pink:       $pink;
  red:        $red;
  orange:     $orange;
  yellow:     $yellow;
  green:      $green;
  teal:       $teal;
  cyan:       $cyan;
  white:      $white;
  boxBackground: $boxBackground;
  boxBorder:  $gray-400;
  boxHover: $off;
  off: $off;
  offText: $offText;
  pinOn: $blue;
  bed: $bed;
  toilet: $toilet;
  text: $text;
  eventBedNormal: #68C772;
  eventBedVacant: $off;
  eventToiletNormal: $toilet;
  eventToiletVacant: $toiletLight;
  eventDanger: #E20000;
  eventDangerHandled: #E2000080;
  eventSystemError: $eventSystemError;
  eventAbnormal: $bed;
  eventAbnormalHandled: #F9B23380;
  eventExtraPerson: #7B68C7;
  eventAlarmOn: #FF0000;
  eventAlarmOff: $off;
  cameraBedNormal: $bed;
  cameraToiletNormal: $toilet;
  cameraError: #E20000;
  cameraOff: $off;
  monitoringOn: $bed;
  monitoringOff: $off;
  handledDanger: #FF000080;
  handledWarning: #F9B23380;
  handledResolved: #68C772;
}