//
// _summary.scss
//

.summary-filter-item-container {
    margin-bottom: 0.1rem;

    .summary-filter-icon-box {
        background-color: $primary;
        height: 38px;
        width: 38px;
        border-radius: 4px;
    }

    .summary-filter-date-box {
        width: 250px;
        margin-left: 6px;
        margin-right: 6px;
        .form-control {
            padding: 0.35rem 0.9rem;
        }
    }

    .summary-filter-floor-box {
        margin-right: 20px;
    }
}

.summary-chart-container {
    margin-top: 10px;
    padding-bottom: calc($footer-height + 10px);
}

.summary-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.summary-title {
    line-height: 28px;
}

.summary-period-box {
    padding: 4px;
    background: #EFF4FD;
    color: #6594F0;
    border-radius: 4px;
    margin-left: 2px;
    margin-right: 2px;
}

.summary-period-box-selected {
    background: #6594F0;
    color: #EFF4FD;
}